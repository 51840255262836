import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Logo from 'components/logo'
import Box from 'components/box'
import Title from 'components/title'
import Video from 'components/video'
import Paragraph from 'components/paragraph'
import Columns from 'components/columns'
import ContactButtons from 'components/contact-buttons'
import Divider from 'components/divider'
import ScrollSign from 'components/scroll-sign'
import DevelopedBy from 'components/developed-by'

const Index = ({ data }) => (
    <Layout seo={data.homeEnJson.seo}>
        <Logo />
        {data.homeEnJson.sections.map(
            (item, i) =>
                item.active && (
                    <Box
                        key={i}
                        bgColor={item.bgColor}
                        leftRightPadding={item.leftRightPadding}
                        hasBorders={item.hasBorders}
                        hasBorderWaveTop={item.hasBorderWaveTop}
                        hasBorderWaveBottom={item.hasBorderWaveBottom}
                        borderWaveRevert={item.borderWaveRevert}
                    >
                        <div id={item.anchor}>
                            {item.title && (
                                <Title accentFirstWord={item.accentFirstWord} contrastText={item.contrastText}>
                                    {item.title}
                                </Title>
                            )}
                            {item.videoUrl && <Video url={item.videoUrl} />}
                            {item.content && (
                                <Paragraph hasEffect={item.hasEffect} contrastText={item.contrastText} fontSize={item.fontSize}>
                                    {item.content}
                                </Paragraph>
                            )}
                            {item.content2 && (
                                <Paragraph hasEffect={item.hasEffect} contrastText={item.contrastText} fontSize={item.fontSize}>
                                    {item.content2}
                                </Paragraph>
                            )}
                            {i === 0 && <ScrollSign />}
                            {item.columns && <Columns items={item.columns} col={item.columnsSize} align={item.columnsAlign} />}
                            {item.list && <Columns items={item.list} />}
                            {item.contactButtons && <ContactButtons buttons={item.contactButtons} />}
                            {item.developedBy && <DevelopedBy href={item.developedBy.href} content={item.developedBy.content} />}
                            {item.hasDivider && <Divider />}
                        </div>
                    </Box>
                )
        )}
    </Layout>
)

Index.propTypes = {
    data: PropTypes.object.isRequired
}

export default Index

export const query = graphql`
    query HomepageQuery {
        homeEnJson {
            title
            seo {
                description
                lang
                navItems {
                    title
                    path
                    anchor
                    type
                }
            }
            sections {
                title
                videoUrl
                accentFirstWord
                contrastText
                fontSize
                leftRightPadding
                content
                content2
                hasEffect
                contactButtons {
                    title
                    type
                    url
                }
                columns {
                    title
                    content
                }
                developedBy {
                    content
                    href
                }
                columnsSize
                columnsAlign
                bgColor
                hasDivider
                hasBorders
                hasBorderWaveTop
                hasBorderWaveBottom
                borderWaveRevert
                anchor
                active
            }
        }
    }
`
